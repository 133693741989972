<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <b>首页</b>
        </el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
          <el-select v-model="filters.merchantName"  placeholder="请选择所属商户" class="" clearable>
              <el-option v-for="item in merchantNames" :key="item.merchantId" :label="item.merchantName" :value="item.merchantName" ></el-option>
          </el-select>
          </el-form-item>

          <el-form-item>
            <el-select v-model="filters.type" placeholder="请选择类型" class="el-select-big" clearable>
              <el-option v-for="item in typeArray" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" v-on:click="reqGetFoodsCategoryListPage" icon="search">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/foodsCategory/add'})">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="foods" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="200" align="center"></el-table-column>
        <el-table-column prop="merchantName" label="在售店铺" min-width="400"></el-table-column>
        <el-table-column prop="foodsCategorytype" label="类型" width="100" align="center" sortable>
          <template slot-scope="{row}">{{ row.type==='1'?'传统美食': row.type==='2'?'网红美食': row.type==='3'?'名优产品':''}}</template>
        </el-table-column>
        <el-table-column prop="readNumber" label="阅读次数" width="80" align="center"></el-table-column>
        <el-table-column prop="star" label="点赞人数" width="80" align="center">
          <template slot-scope="{row}">{{ row.star || '0' }}</template>
        </el-table-column>
        <el-table-column prop="recommend" label="推荐" width="80" align="center">
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.recommend)==0}">{{ scope.row.recommend==='0'?'是': scope.row.recommend==='1'?'否':''}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="isTop" label="置顶" width="80" align="center">
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.isTop)==0}">{{scope.row.isTop==='0'?'是': scope.row.isTop==='1'?'否':''}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="status" label="状态" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{temporary: (scope.row.status)==1}">{{scope.row.status==0?'已发布':'草稿'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="publisher" label="发布人" width="100" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="修改时间" width="160" align="center"  sortable>
          <template slot-scope="{row}">{{ row.updateTime || '暂无更改' }}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" align="center" :formatter="formatCreateTime" sortable></el-table-column>

        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view" @click="$router.push({path:'/foodsCategory/view',query:{id:scope.row.id}})">查看</el-button>
            <el-button type="primary" size="small" v-if="permissionButton.edit" @click="$router.push({path:'/foodsCategory/edit',query:{id:scope.row.id}})">编辑</el-button>
            <el-button type="danger" @click="reqDeleteFoodsCategory(scope.$index,scope.row)" size="small" v-if="permissionButton.del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="reqBatchDeleteFoodsCategory" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10,15,20,25,50]" :page-size="pageSize" layout=" total, sizes, prev, pager, next, jumper" :total="total" style="float:right;"></el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
import { reqGetDictionaryListByCode, downloadFileURL,} from "../../../api/system-api";
import { getMerchantNames} from "../../../api/foods-api";
import { reqGetFoodsCategoryListPage,reqDeleteFoodsCategory,reqBatchDeleteFoodsCategory} from "../../../api/foodsCategory-api";
import permission from "../../../common/permission";
import util from "../../../common/util";

export default {
  data() {
    return {
      emptyText: "暂无数据",
      downloadFileURL: downloadFileURL,
      foods: [],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      listLoading: false,
      sels: [],
      permissionButton: new permission.PermissionButton(),
      merchantNames:[],
      filters: {
        name:"",
        type:"",
        merchantName:""
      },
      typeArray:[
          {
              value: "1",
              label: "传统美食",
          },
          {
              value: "2",
              label: "网红美食",
          },
          {
              value: "3",
              label: "名优产品",
          },
      ],
    };
  },
  methods: {
    //查询商户名称
    getMerchantNames:function(){
      getMerchantNames().then(res=>{
           if (res.data.code === 1) {
            this.merchantNames = res.data.data;
            } else {
              that.$message({
                message: "查询失败",
                type: "error",
              });
            }
      })
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.currentPage = val;
      this.reqGetFoodsCategoryListPage();
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.reqGetFoodsCategoryListPage();
    },

    goReset() {
      this.filters.name = '';
      this.filters.type='';
      this.filters.merchantName='';
      this.pageNum = 1;
      this.reqGetFoodsCategoryListPage();
    },
    reqGetFoodsCategoryListPage() {
      let para = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.filters.name,
        type: this.filters.type,
        merchantName: this.filters.merchantName,
      };
      this.listLoading = true;
      reqGetFoodsCategoryListPage(para).then((res) => {
        this.total = res.data.data.total;
        this.foods = res.data.data.records;
        this.listLoading = false;
      });
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    formatUpdateTime: function (row) {
      if (row.updateTime) {
        return util.formatDate.format(
          new Date(row.updateTime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
    },
    formatCreateTime: function (row) {
      if (row.createTime) {
        return util.formatDate.format(
          new Date(row.createTime),
          "yyyy-MM-dd hh:mm:ss"
        );
      }
    },
    formatType:function(row){
        if(row.type){
            return row.type==='1'?'传统美食':row.type==='2'?'网红美食':row.type==='3'?'特色小吃':row.type==='0'?'热门美食':''
        }
    },
    getDictionarys: function () {
      var that = this;
      reqGetDictionaryListByCode({ code: "columns_type" }).then((res) => {
        that.columnsTypeArray = res.data.data;
      });
    },

    // 删除
    reqDeleteFoodsCategory: function (index, row) {
      this.$confirm("确认删除该记录吗?", "提示", { type: "warning" })
        .then(() => {
          this.listLoading = true;
          let para = { id: row.id };
          reqDeleteFoodsCategory(para).then((res) => {
            if (res.data.code === 1) {
              this.listLoading = false;
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.reqGetFoodsCategoryListPage();
            } else {
              this.listLoading = false;
              this.$message({
                message: "删除失败",
                type: "error",
              });
              this.reqGetFoodsCategoryListPage();
            }
          });
        })
        .catch(() => {});
    },
    // 批量删除
    reqBatchDeleteFoodsCategory: function () {
      var ids = this.sels.map((item) => item.id).toString();
      this.$confirm("确认删除选中记录吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          let para = { ids: ids };
          reqBatchDeleteFoodsCategory(para).then((res) => {
            if (res.data.code === 1) {
              this.listLoading = false;
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.reqGetFoodsCategoryListPage();
            } else {
              this.listLoading = false;
              this.$message({
                message: "删除失败",
                type: "error",
              });
              this.reqGetFoodsCategoryListPage();
            }
          });
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.reqGetFoodsCategoryListPage();
    this.getMerchantNames();
    var permissionId = sessionStorage.getItem("permissionId");
    permission.getPermissionButton(permissionId, this.permissionButton);
  },
};
</script>
